import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    consumerProducts: [
      {
        id: 1,
        coverImg: require("../assets/consumer-products/app_secuity.jpg"),
        title: "App Security",
        desc: "Secure your Personal Apps with our App security App.",
        link:
          "https://play.google.com/store/apps/details?id=com.wtxhub.appsecurity"
      },
      {
        id: 2,
        coverImg: require("../assets/consumer-products/GTP.jpg"),
        title: "Great for the Planet",
        desc: "Save the planet, stay carbon neutral",
        link: "https://greatfortheplanet.com/business"
      },
      {
        id: 3,
        coverImg: require("../assets/consumer-products/abs_chef.jpg"),
        title: "Absolutely Chef",
        desc:
          "Search for your career advancement and find work across all Food and Beverage related industries",
        link: ""
      },
      // {
      //   id: 4,
      //   coverImg: require("../assets/consumer-products/coffeerun.jpg"),
      //   title: "CoffeeRun",
      //   desc: "Pick up App for Coffee and Cakes",
      //   type: "consumer"
      // },
      {
        id: 5,
        coverImg: require("../assets/consumer-products/foodcamera.jpg"),
        title: "Food Camera",
        desc:
          "The Food Camera App is a photo editing App for taking great pictures of your Food & Drinks.",
        link:
          "https://play.google.com/store/apps/details?id=com.wtxhub.foodimages"
      },
      {
        id: 6,
        coverImg: require("../assets/consumer-products/foodfreshness.jpg"),
        title: "Food Freshness",
        desc: "Maximise Freshness of Food and minimise your Food waste",
        link:
          "https://play.google.com/store/apps/details?id=com.wtxhub.manageproduct"
      },
      {
        id: 7,
        coverImg: require("../assets/consumer-products/sip_of_water.jpg"),
        title: "Sip of Water",
        desc: "One Stop shop for water consumption and tracking",
        link:
          "https://play.google.com/store/apps/details?id=com.wtxhub.waterconsumption"
      },
      {
        id: 8,
        coverImg: require("../assets/consumer-products/Agood_brew.jpg"),
        title: "A Good Brew",
        desc: "A one stop guide to make that great cup of Tea",
        link:
          "https://play.google.com/store/apps/details?id=com.wtxhub.agoodbrew"
      },
      {
        id: 9,
        coverImg: require("../assets/consumer-products/Search_for_Eats.jpg"),
        title: "Search for Eats",
        desc:
          "The ideal App to assist you to find your preferred dining experience",
        link:
          "https://play.google.com/store/apps/details?id=com.wtxhub.searchforeats"
      },
      {
        id: 10,
        coverImg: require("../assets/consumer-products/Gifting.jpg"),
        title: "Christmas List and Gifting",
        desc:
          "The easy-to-use app to help you organize your Christmas and all year round present lists and budget",
        link:
          "https://play.google.com/store/apps/details?id=com.wtxhub.presentapp"
      },
      {
        id: 11,
        coverImg: require("../assets/consumer-products/seasonal_food.jpg"),
        title: "Seasonal Foods",
        desc:
          "Search the current season or all seasons to see if certain produce is in season",
        link:
          "https://play.google.com/store/apps/details?id=com.wtxhub.seasonalfood"
      }
    ],
    businessProducts: [
      {
        id: 1,
        coverImg: require("../assets/business-products/NFC.jpg"),
        title: "NFC All in one App",
        desc:
          "An all in one App that writes to NFC Tags and makes reading NFC Tags, QR Codes and Barcodes easy",
        link: "https://play.google.com/store/apps/details?id=com.wtxh.nfcall"
      },
      {
        id: 2,
        coverImg: require("../assets/business-products/App_Secuity.jpg"),
        title: "App Security Business",
        desc:
          "Keep your business mobile devices secure with this Comprehensive Security",
        link:
          "https://play.google.com/store/apps/details?id=com.wtxhub.appsecurity"
      },
      {
        id: 3,
        coverImg: require("../assets/business-products/GTP.jpg"),
        title: "Great for the Planet Business",
        desc: "Ensure your business meets all Carbon Neutral requirements",
        link: "https://greatfortheplanet.com/business"
      },
      {
        id: 4,
        coverImg: require("../assets/business-products/F&B.jpg"),
        title: "F&B Links",
        desc: "A Food and Beverage Industry lead and sourcing platform",
        link: "http://fandblinks.com/"
      },
      {
        id: 5,
        coverImg: require("../assets/business-products/Abs_Chef.jpg"),
        title: "Absolutely Chef",
        desc:
          "Fulfil all your F&B Recruitment Requirements with this Specialist Recruitment online platform",
        link: ""
      },
      {
        id: 6,
        coverImg: require("../assets/business-products/nifty.jpg"),
        title: "Nifty Document Scanner",
        desc: "A Camera document scanner with high quality PDF and JPEG output",
        link:
          "https://play.google.com/store/apps/details?id=com.wtxhub.niftydocument"
      }
    ]
  },
  getters: {
    businessProducts: state => {
      return state.businessProducts;
    },
    consumerProducts: state => {
      return state.consumerProducts;
    }
  },
  mutations: {},
  actions: {},
  modules: {}
});
